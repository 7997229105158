<template>
    <div>

        <ConfirmationDialog v-bind="confirmationDialogParameters"
                            v-on:delete-selected-deliveries="deleteSelectedDeliveries"/>

        <RecordDialog v-if="showRecordDialog" v-bind="recordDialogParameters"
                      v-on:hidden="showRecordDialog = false"
                      v-on:success-create-inventory-movement="successCreateInventoryMovement"
                      v-on:success-delete-delivery="successDeleteDelivery"
                      v-on:success-delete-inventory-movement="successDeleteInventoryMovement"
                      v-on:success-update-delivery="successUpdateDelivery"
                      v-on:success-update-inventory-movement="successUpdateInventoryMovement"/>

        <div class="card st-framework-card" v-if="pageLoaded">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 240px; border: solid 1px lightgray;">
                            <font-awesome-icon :icon="icon" size="sm" class="mr-2"/>
                            <template v-for="(action,index) in actions.manageActions">
                                <SelectAction :key="action.name + '-' + index"
                                              :action="action" :disabled="state.disabled"
                                              v-on:select-view="selectView"
                                              v-if="action.type === 'SELECT' && action.placement === 'DOCUMENTHEADERLEFT'"/>
                            </template>
                        </td>
                        <td style="width: 38px;"/>
                        <td style="width: 22px; text-align: left;">
                            <font-awesome-icon :icon="['fas', 'lock']" size="sm" v-if="data.skellet_product"/>
                        </td>
                        <td style="margin: 0; padding: 0 10px; border: solid 1px lightgray;">
                            <SelectField :field="fields.selectField" v-on:input="productSelectInput"/>
                        </td>
                        <td style="margin: 0; padding: 0; width: 90px; text-align: right;">(#{{ data.id }})</td>
                        <td style="width: 1rem;"></td>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-auto">
                                <QrCode :value="data.qr_code"/>
                            </div>
                            <div class="col">
                                <table class="table table-borderless table-sm" style="margin-bottom: 0">
                                    <tbody>
                                    <tr>
                                        <td class="p-0" style="width: 150px;"><b>{{ $i18n.tc('translations.Category', 1) }}</b></td>
                                        <td class="p-0">{{ data.product_category_id.label }}</td>
                                    </tr>
                                    <tr v-if="data.article_number">
                                        <td class="p-0" style="width: 150px;"><b>{{ $i18n.t('translations.Article Number') }}</b></td>
                                        <td class="p-0">{{ data.article_number }}</td>
                                    </tr>
                                    <tr v-if="data.matchcode">
                                        <td class="p-0" style="width: 150px;"><b>{{ $i18n.t('translations.Matchcode') }}</b></td>
                                        <td class="p-0">{{ data.matchcode }}</td>
                                    </tr>
                                    <tr v-if="data.description_translation">
                                        <td class="p-0" style="width: 150px;"><b>{{ $i18n.t('translations.Description') }}</b></td>
                                        <td class="p-0">{{ data.description_translation }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-auto" v-if="data.inventory_product">
                                <div class="px-2 py-1" :style="{'color': (data.inventory < 0 ? 'red' : 'black')}">
                                    <b>{{ $i18n.t('translations.Inventory') }}:</b> {{ data.inventory_f }} {{ data.unit_id.label }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Table :key="'movement-'+reloadInventoryMovementTable" header-class="light" footer-class="light"
                       :title="$i18n.tc('translations.Inventory Movement',2)" :api="api+'/crud/'+data.id"
                       withFilter :state="state"
                       v-on:create-row="createInventoryMovement"
                       v-on:delete-row="deleteInventoryMovement"
                       v-on:detail-row="detailInventoryMovement"
                       v-on:update-row="updateInventoryMovement"/>
                <Table :key="'delivery-'+reloadDeliveryTable" header-class="light" footer-class="light"
                       :title="$i18n.tc('translations.Delivery',2)" :api="'/deliveries/crud/'+data.id"
                       withFilter :state="state"
                       v-on:delete-row="deleteDelivery"
                       v-on:delete-selection="confirmDeleteSelectedDeliveries"
                       v-on:detail-row="detailDelivery"
                       v-on:download-qr-code="downloadQrCode"
                       v-on:update-row="updateDelivery"/>
            </div>
        </div>

    </div>
</template>

<script>
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog";
import FileDownload from "@/mixins/FileDownload";
import QrCode from "@/components/QrCode";
import RecordDialog from "@/components/dialogs/RecordDialog";
import SelectField from "@/components/forms/fields/SelectField.vue";
import Table from "@/components/Table";
import SelectAction from "@/components/actions/SelectAction.vue";

export default {
    name: "InventoryMovements",
    components: {
        SelectAction,
        ConfirmationDialog,
        QrCode,
        RecordDialog,
        SelectField,
        Table,
    },
    props: ['name', 'icon', 'api', 'state', 'userLanguage', 'languages'],
    mixins: [FileDownload],
    data() {
        return {
            pageLoaded: false,
            actions: null,
            fields: null,
            data: null,
            confirmationDialogParameters: {
                type: '',
                title: '',
                message: '',
                ok_event: 'ok',
                ok_text: 'OK',
                cancel_event: 'cancel',
                cancel_text: this.$i18n.t("translations.Cancel"),
                data: null,
            },
            showRecordDialog: false,
            recordDialogParameters: {
                type: null,
                title: null,
                api: null,
                fields: null,
                data: null,
                ok_label: null,
                ok_event: null,
                languages: this.languages,
                userLanguage: this.userLanguage,
            },
            reloadInventoryMovementTable: 0,
            reloadDeliveryTable: 0,
        }
    },
    watch: {
        $route() {
            this.fetchData(parseInt(this.$route.params.id));
        }
    },
    created() {
        this.fetchData(parseInt(this.$route.params.id));
    },
    methods: {
        confirmDeleteSelectedDeliveries(choice) {
            this.confirmationDialogParameters.type = 'delete';
            this.confirmationDialogParameters.title = this.$i18n.t("translations.Delete Selected Deliveries");
            this.confirmationDialogParameters.message = this.$i18n.t("translations.Are you sure you want to delete the selected deliveries?");
            this.confirmationDialogParameters.data = choice;
            this.confirmationDialogParameters.ok_event = 'delete-selected-deliveries';
            this.confirmationDialogParameters.ok_text = this.$i18n.t("translations.Delete");
            this.confirmationDialogParameters.cancel_text = this.$i18n.t("translations.Cancel");
            this.$bvModal.show('confirmation-dialog');
        },
        createInventoryMovement(data) {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc('translations.Inventory Movement', 1);
            this.recordDialogParameters.api = this.api;
            this.recordDialogParameters.fields = this.inventoryMovementFields(data.type);
            this.recordDialogParameters.data = {
                type: data.type,
                product_id: this.data,
            };
            this.recordDialogParameters.ok_event = 'success-create-inventory-movement';
            this.showRecordDialog = true;
        },
        deleteDelivery(data) {
            this.recordDialogParameters.type = "delete";
            this.recordDialogParameters.title = this.$i18n.tc('translations.Delivery', 1);
            this.recordDialogParameters.api = '/deliveries';
            this.recordDialogParameters.fields = this.fields.deliveryFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_event = 'success-delete-delivery';
            this.showRecordDialog = true;
        },
        deleteInventoryMovement(data) {
            this.recordDialogParameters.type = "delete";
            this.recordDialogParameters.title = this.$i18n.tc('translations.Inventory Movement', 1);
            this.recordDialogParameters.api = this.api;
            this.recordDialogParameters.fields = this.inventoryMovementFields(data.type);
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_event = 'success-delete-inventory-movement';
            this.showRecordDialog = true;
        },
        deleteSelectedDeliveries(choice) {
            this.state.loading = true;
            this.$http.post(choice.api, {ids: choice.ids}, {}).then(() => {
                this.reloadDeliveryTable++;
                this.state.loading = false;
            }).catch((error) => {
                console.log("InventoryMovements:deleteSelectedRules():error:", error);
            });
        },
        detailDelivery(data) {
            this.recordDialogParameters.type = "detail";
            this.recordDialogParameters.title = this.$i18n.tc('translations.Delivery', 1);
            this.recordDialogParameters.api = null;
            this.recordDialogParameters.fields = this.fields.deliveryFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_label = null;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        detailInventoryMovement(data) {
            this.recordDialogParameters.type = "detail";
            this.recordDialogParameters.title = this.$i18n.tc('translations.Inventory Movement', 1);
            this.recordDialogParameters.api = null;
            this.recordDialogParameters.fields = this.inventoryMovementFields(data.type);
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_label = null;
            this.recordDialogParameters.ok_event = 'ok';
            this.showRecordDialog = true;
        },
        fetchData(id) {
            this.state.loading = true;
            this.pageLoaded = false;
            this.$http.get(this.api + '/manage/' + id, {}).then((res) => {
                this.setData(res.data);
                this.pageLoaded = !!this.data;
                this.state.loading = false;
            }).catch((error) => {
                this.data = null;
                console.log("InventoryMovements:fetchData():error:", error);
            });
        },
        inventoryMovementFields(type) {
            switch (type) {
                case 'CORRECTION':
                    return this.recordDialogParameters.fields = this.fields.inventoryFieldsCorrection;
                case 'IN':
                    return this.recordDialogParameters.fields = this.fields.inventoryFieldsIn;
                case 'INVENTORY':
                    return this.recordDialogParameters.fields = this.fields.inventoryFieldsInventory;
                case 'OUT':
                    return this.recordDialogParameters.fields = this.fields.inventoryFieldsOut;
            }
        },
        productSelectInput(data) {
            if (data['id'] !== parseInt(this.$route.params.id)) {
                this.$router.push({params: {id: data['id']}});
            }
        },
        downloadQrCode(choice) {
            this.state.loading = true;
            this.$http.post(choice.api, {
                ...(choice.writer_type && {'writer_type': choice.writer_type}),
                'ids': choice.ids
            }, {
                ...(choice.filename && {'responseType': 'blob'}),
            }).then((res) => {
                if (choice.filename) {
                    this.fileDownload(choice, res.data);
                }
                this.state.loading = false;
            }).catch((error) => {
                console.log("InventoryMovements:downloadQrCode():error:", error);
            });
        },
        selectView(option) {
            this.$router.push({name: option.routename, params: {id: this.data.id}});
        },
        setData(data) {
            this.data = data.data;
            this.actions = data.actions;
            this.fields = data.fields;
        },
        successCreateInventoryMovement(data) {
            this.data = data.data.product_id;
            this.reloadInventoryMovementTable++;
        },
        successDeleteDelivery() {
            this.reloadDeliveryTable++;
        },
        successDeleteInventoryMovement(data) {
            this.data = data.data;
            this.reloadInventoryMovementTable++;
        },
        successUpdateDelivery() {
            this.reloadDeliveryTable++;
        },
        successUpdateInventoryMovement(data) {
            this.data = data.data.product_id;
            this.reloadInventoryMovementTable++;
        },
        updateDelivery(data) {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc('translations.Delivery', 1);
            this.recordDialogParameters.api = '/deliveries';
            this.recordDialogParameters.fields = this.fields.deliveryFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_event = 'success-update-delivery';
            this.showRecordDialog = true;
        },
        updateInventoryMovement(data) {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc('translations.Inventory Movement', 1);
            this.recordDialogParameters.api = this.api;
            this.recordDialogParameters.fields = this.inventoryMovementFields(data.type);
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_event = 'success-update-inventory-movement';
            this.showRecordDialog = true;
        },
    }
}
</script>

<style scoped>
</style>
