import { render, staticRenderFns } from "./InventoryMovements.vue?vue&type=template&id=3fdbdf70&scoped=true&"
import script from "./InventoryMovements.vue?vue&type=script&lang=js&"
export * from "./InventoryMovements.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fdbdf70",
  null
  
)

export default component.exports